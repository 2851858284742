
<template>
    <div>
        <!-- Posts grid -->
        <div v-if="posts.length" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div v-for="post in posts" :key="post.id" class="bg-white shadow-md rounded-lg overflow-hidden">
                <div class="h-48 bg-gray-200 flex items-center justify-center">
                    <img v-if="post.image_url" :src="post.image_url" :alt="post.title" class="w-full h-full object-cover">
                    <svg v-else class="w-16 h-16 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clip-rule="evenodd" />
                    </svg>
                </div>
                <div class="p-6">
                    <h2 class="text-xl font-bold mb-2" v-html="decodeHtmlEntities(post.title)"></h2>
                    <p class="text-gray-600 mb-4" v-html="post.excerpt"></p>
                    <!--<a :href="getRelativeLink(post.link)" class="text-blue-500 hover:underline">Read more</a>-->
                    <a :href="getRelativeLink(post.link)" class="flex items-center text-blue-500 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-transform duration-200 hover:transform hover:translate-x-1">
                        <span class="text-lg font-medium">Read more</span>
                        <svg class="w-4 h-4 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                        </svg>
                    </a>
                </div>

                <div v-if="isCategoryShow && post.categories.length" class="p-6">
                    <div class="flex flex-wrap gap-2">
                    <div v-for="category in post.categories" :key="category.id" class="bg-gray-200 rounded-full px-4 py-2">
                        <a :href="generateLink(category)" class="text-blue-500 hover:underline hover:text-blue-700">{{ category }}</a>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Pagination -->
        <div v-if="isPaginationShow" class="mt-8 flex justify-center">
            <button
                @click="changePage(currentPage - 1)"
                :disabled="currentPage === 1"
                class="px-4 py-2 bg-blue-500 text-white rounded-l-md disabled:bg-gray-300"
            >
            Previous
            </button>
            <span class="px-4 py-2 bg-gray-200">{{ currentPage }} of {{ totalPages }}</span>
            <button
                @click="changePage(currentPage + 1)"
                :disabled="currentPage === totalPages"
                class="px-4 py-2 bg-blue-500 text-white rounded-r-md disabled:bg-gray-300"
            >
            Next
            </button>
        </div>
     </div>
</template>

<script setup>
import { defineProps } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();

const props = defineProps({
  posts: Array,
  currentPage: Number,
  totalPages: Number,
  isPaginationShow: Boolean,
  isCategoryShow: Boolean,
  postType:String
});

const getRelativeLink = (fullLink) => {
  const url = new URL(fullLink);
  return url.pathname;
};

const generateLink = (slug) => {
  const basePath = 'category';
  const formattedSlug = slug.toLowerCase().replace(/\s+/g, '-');
  return `/${basePath}/${formattedSlug}`;
};

const decodeHtmlEntities = (text) => {
  const textArea = document.createElement('textarea');
  textArea.innerHTML = text;
  return textArea.value;
};

const changePage = (newPage) => {
    console.log('totalPages' + props.totalPages);
    console.log('currentPage' + props.currentPage);
    console.log('postType : ' + props.postType);

    if (newPage >= 1 && newPage <= props.totalPages) {
        //router.push({path:  `/blog/page/${newPage}`});
        
        if(props.postType == 'Blog'){
            router.push({path:  `/${props.postType}/page/${newPage}`});
        }
        if(props.postType == 'Category'){
            const categoryName = route.params.category;
            router.push({path:  `/${props.postType}/${categoryName}/page/${newPage}`});
        }
    }
};

</script>

  